body {
    font-size: 15px;
    font-family: 'Montserrat' sans-serif;
    .App{
        @import "Top";
        @import "Header";
        @import "Home";
        @import "Footer";
        @import "Pregrado";
        @import "TallerPregrado";
        @import "PreguntasPregrado";
        @import "CoordinacionPregrado";
    }
}