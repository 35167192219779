.hover-effect:hover{
    transform: scale(1.05);
    cursor: pointer; 

}

.custom-button-idioma {
    background-color: transparent; /* Fondo transparente */
    border: none; /* Sin borde */
    padding: 0.5rem 1rem; /* Ajustar el padding según tus necesidades */
    font-size: 14px; /* Ajustar el tamaño de la fuente según tus necesidades */
  }
  
  /* Si quieres que ambos botones sean del mismo tamaño, también puedes agregar un ancho fijo a .custom-button */
  .custom-button-idioma {
    width: 100px; /* O cualquier otro valor fijo */
    /* Resto del estilo */
  }
.postula-aqui{
  text-decoration: none;
  color: black;
  transition: color 0.3s;
}
.postula-aqui:hover {
  color: #00a4d3; /* Cambia a tu color deseado */
}