.bg-uai{
    background: #00a4d3;
}
.p-custom{
    height: 169px;
    width: 1265px;
}
.img-header{
    margin-left: 35px !important;
    margin-bottom: 35px !important;
}