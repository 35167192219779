.blurred-image {
    filter: blur(0px); /* Ajusta el valor según el nivel de desenfoque deseado */
    position: relative; /* Necesario para el pseudo-elemento */
    filter: brightness(0.8); /* Ajusta el valor para oscurecer la imagen */

}
.imagen-oscura {
    filter: brightness(0.5); /* Ajusta el valor para oscurecer la imagen */
}

.card-img-top {
    width: 100%;
    height: 200px; /* Ajusta la altura según tus necesidades */
    object-fit: cover; /* Asegura que la imagen se recorte para llenar el contenedor */
}