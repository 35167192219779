.custom-size-logo{
    height: 14%;
    width: 8%;
}
.custom-size-card{
    height: 421px;
    width: 265px;
}

.bg-facebook{
    background: #39579a;
}
.fc-color{
    color: #44549A;
}
.bg-twitter{
    background: #009fe3;
}
.bg-lin{
    background: #3474B6;
}
.bg-black{
    background-color: #00000080;
}

.custom-size-small-logo{
    height: 40%;
    width: 20%;
}
.custom-font{
    font-family: 'Montserrat' sans-serif;
}

.custom-deco{
    height: 40px;
}
.custom-logo-position{
    margin-left: 1010px !important;
    margin-bottom: 40px !important;
}
.custom-line-size{
    width: 30px;
    height: 4px;
}
.custom-line-l-size{
    width: 70px;
    height: 4px;
}
.bg-grey{
    background: #f4f4f4;
}
.bg-grey-dark{
    background: #d9d9d9;
}
.color-uai{
    color: #00a4d3;
}